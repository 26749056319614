import * as actions from "./constants";
import * as statuses from "../../store/StatusTypes";
import {convertToArrayOfObjects} from "../../helpers/utils";

const initialState = {
    status: null,
    limit: 10,
    offset: 0,
    total: 0,
    list: [],
    search: '',
    lastUpdate: null,
    categoriesList: [],
    selectedCategory: [],
    count: '',
    isEmailsCountLoading: false,
    isFiltersSelected: false,
};

export default function close(state = initialState, action) {
    switch (action.type) {
        case actions.REQUEST_INIT_CLOSE:
            return {
                ...state,
                status: statuses.INIT_CLOSE_REQUESTED,
            }
        case actions.SUCCEED_INIT_CLOSE:
            return {
                ...state,
                status: statuses.INIT_CLOSE_SUCCEEDED,
                list: action.response.result,
                total: action.response.total,
            }
        case actions.REQUEST_SEARCH_CLOSE:
            return {
                ...state,
                status: statuses.SEARCH_CLOSE_REQUESTED,
                limit: action.limit,
                offset: action.offset
            }
        case actions.SUCCEED_SEARCH_CLOSE:
            return {
                ...state,
                status: statuses.SEARCH_CLOSE_SUCCEEDED,
                list: [...state.list, ...action.response.result],
                total: action.response.total
            }
        case actions.REQUEST_LAST_UPDATE_CLOSE:
            return {
                ...state,
                lastUpdate: null,
            }
        case actions.SUCCEED_LAST_UPDATE_CLOSE:
            return {
                ...state,
                lastUpdate: action.lastUpdate,
            }
        case actions.SET_CLOSE_SEARCH:
            return {
                ...state,
                search: action.search,
                list: [],
                limit: 10,
                offset: 0
            }
        case actions.SUCCEED_CLOSE_FILTER_DATA:
            return {
                ...state,
                categoriesList: [...state.categoriesList, ...convertToArrayOfObjects(action.response.categories)],
                selectedCategory: [...state.categoriesList, ...convertToArrayOfObjects(action.response.categories)]
            }
        case actions.SET_CLOSE_CATEGORY:
            return {
                ...state,
                selectedCategory: action.selectedCategory,
            }
        case actions.REQUEST_GET_COUNT_CLOSE:
            return {
                ...state,
                isEmailsCountLoading: true
            }
        case actions.SUCCEED_GET_COUNT_CLOSE:
            return {
                ...state,
                count: action.count,
                isEmailsCountLoading: false
            }
        case actions.SET_IS_FILTER_SELECTED_CLOSE:
            return {
                ...state,
                isFiltersSelected: action.isFiltersSelected,
            }
        case actions.CLEAR_FILTERS_CLOSE:
            return {
                ...state,
                selectedCategory: state.categoriesList,
            }
        default:
            return state;
    }
}